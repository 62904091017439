export default {
  NewPassword__ALLOWED_CHARACTERS: "May include only these special characters !@#$%^*();:/ \\",
  NewPassword__CASE: "Must contain at least one uppercase letter",
  NewPassword__DISALLOWED_NUMERIC_SEQUENCE: "May not contain 12345",
  NewPassword__DISALLOWED_STRING_SEQUENCE: "May not contain Password",
  NewPassword__FIRST_CHARACTER: "May not start with special characters",
  NewPassword__HIDE: "Hide",
  NewPassword__LENGTH: "Must be 8-16 characters in length",
  NewPassword__NUMBER: "Must contain at least one number",
  NewPassword__SHOW: "Show",
};
