export default {
  ErrorPage__DATE_TIME_24_HOUR_FORMAT: "MM/DD/YYYY, HH:mm:ss",
  ErrorPage__ERROR_DETAILS: "Error details",
  ErrorPage__ERROR_INCIDENT_IDENTIFIER: "Incident Identifier",
  ErrorPage__ERROR_LEGEND: "Error Code",
  ErrorPage__ERROR_UI:
    "An unexpected error occurred. We are working diligently to fix this issue. If you continue to have difficulties, please contact a Southwest Airlines® Customer Representative at 1-800-I-FLY-SWA (1-800-435-9792).",
  ErrorPage__OOPS_PAGE: "We apologize for the inconvenience",
  ErrorPage__RESTART_FLOW: "Start over",
  ErrorPage__RETURN_HOME: "Back to home",
  ErrorPage__SOMETHING_WENT_WRONG: "Something went wrong",
};
