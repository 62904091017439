export default {
  ManageMyVacations__CAPTION_DESCRIPTION:
    "Already booked a package with Getaways by Southwest? Retrieve the details here.",
  ManageMyVacations__FORM_FIRSTNAME_LABEL: "First name",
  ManageMyVacations__FORM_FIRSTNAME_REQUIRED_TEXT: "Please enter first name",
  ManageMyVacations__FORM_CONFIRMATION_NUMBER_HELPER_TEXT: "Please enter 6 or 7 characters",
  ManageMyVacations__FORM_CONFIRMATION_NUMBER_INVALID_TEXT:
    "Please enter valid flight confirmation",
  ManageMyVacations__FORM_CONFIRMATION_NUMBER_LABEL: "Vacation or flight confirmation #",
  ManageMyVacations__FORM_LASTNAME_LABEL: "Last name",
  ManageMyVacations__FORM_LASTNAME_REQUIRED_TEXT: "Please enter last name",
  ManageMyVacations__FORM_NAME_INVALID_TEXT: "Please enter valid name",
  ManageMyVacations__FORM_SUBMIT_BUTTON_TEXT: "Find my vacation booking",
};
