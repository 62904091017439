import AppFooterKeys from "./AppFooter/AppFooter.i18n";
import ContextVisualizer from "./ContextVisualizer/ContextVisualizer.i18n";
import MaintenanceMessageKeys from "./Maintenance/MaintenanceMessage.i18n";
import MessageProviderI18nKeys from "./MessageProvider/MessageProvider.i18n";
import ServiceErrorMessageKeys from "./MessageProvider/ServiceErrorMessage.i18n";
import ErrorPageKeys from "./Page/ErrorPage/ErrorPage.i18n";
import SkipToContentKeys from "./Page/SkipToContent.i18n";
import VersionKeys from "./Version/Version.i18n";

export default {
  ...AppFooterKeys,
  ...ContextVisualizer,
  ...ErrorPageKeys,
  ...MaintenanceMessageKeys,
  ...MessageProviderI18nKeys,
  ...ServiceErrorMessageKeys,
  ...SkipToContentKeys,
  ...VersionKeys,
};
