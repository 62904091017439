export default {
  useVacationsFormLogic__BOOKABLE_DATE_SCHEDULE_EXTENSION:
    "Check back soon for our next schedule extension.",
  useVacationsFormLogic__CALENDAR_CAPTION_FORMAT: "ddd, MMM DD, YYYY",
  useVacationsFormLogic__CALENDAR_DATE_STRING_FORMAT: "MMMM DD, YYYY",
  useVacationsFormLogic__CALENDAR_INPUT_FORMAT: "MM/DD",
  useVacationsFormLogic__CLOSE: "Close",
  useVacationsFormLogic__DATE_SELECTOR_TITLE: "Select your travel dates",
  useVacationsFormLogic__DAYS_OF_WEEK_INITIALS: "SMTWTFS",
  useVacationsFormLogic__DEPART_DATE: "Depart",
  useVacationsFormLogic__DEPART_DATE_ARIA: "Depart date in M M, D D format",
  useVacationsFormLogic__FROM: "From",
  useVacationsFormLogic__FIND_A_VACATION: "Find a vacation",
  useVacationsFormLogic__INVALID_ROUTE: "Enter a valid departure airport",
  useVacationsFormLogic__LAST_BOOKABLE_DATE: (airDatesData) =>
    `We are currently accepting vacations package booking through ${airDatesData.formattedCurrentLastDateString}.`,
  useVacationsFormLogic__NO_MATCH_FOUND: "No match found",
  useVacationsFormLogic__ON_OPEN_SCHEDULE: (airDatesData) =>
    `On ${airDatesData.formattedFutureOpenDateString} we will open our schedule for sale through ${airDatesData.formattedFutureCloseDateString}.`,
  useVacationsFormLogic_PromoCode__FORM_FIELD_LABEL: "Enter Promo Code",
  useVacationsFormLogic__RETURN_DATE: "Return",
  useVacationsFormLogic__RETURN_DATE_ARIA: "Return date in M M, D D format",
  useVacationsFormLogic__SUBJECT_TO_CHANGE:
    "This date is subject to change! Please check back frequently.",
  useVacationsFormLogic__TO: "To",
};
