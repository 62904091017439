import React from "react";

export const Superscript = (props) => {
  const { nodes } = props;
  const comps = [];

  nodes.forEach((node, index) => {
    comps.push(index % 2 ? <sup key={index}>{node}</sup> : <span key={index}>{node}</span>);
  });

  return comps;
};
