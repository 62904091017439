import React from "react";

import { token } from "../config";
import { Superscript } from "../Superscript";

export default function superscript(message) {
  const nodes = message?.split?.(token.superscript) ?? [];

  return nodes.length > 1 ? <Superscript nodes={nodes} /> : message;
}
