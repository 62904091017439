export default {
  MissingInformationRedirectDialog__CONTINUE_AS_GUEST: "Continue as guest",
  MissingInformationRedirectDialog__CORPORATE_MESSAGE:
    "Your account is not associated with that company. Please login on the corporate home page and associate your account.",
  MissingInformationRedirectDialog__CORPORATE_REDIRECT_ARIA_LABEL:
    "Clicking here closes modal and redirects to corporate home page.",
  MissingInformationRedirectDialog__GUEST_ARIA_LABEL: "Clicking here closes modal.",
  MissingInformationRedirectDialog__HEADING: "We're sorry...",
  MissingInformationRedirectDialog__MESSAGE:
    "Your account seems to be missing information. Please log in on the homepage to complete your profile.",
  MissingInformationRedirectDialog__REDIRECT_ARIA_LABEL:
    "Clicking here to closes modal and redirects to Southwest.com homepage.",
  MissingInformationRedirectDialog__REDIRECT_TO_HOMEPAGE: "Redirect to homepage",
};
