export default {
  LoginForm__BUTTON__CANCEL: "Cancel",
  LoginForm__ERROR_ACCOUNT_DISABLED:
    "This account is closed and any associated points are no longer available for use.",
  LoginForm__ERROR_ACCOUNT_HELD:
    "Your account is in a held status and cannot be accessed. For information on how to resolve, visit www.southwest.com/accounthelp",
  LoginForm__ERROR_ACCOUNT_NUMBER_LONG: "The Account number you entered is too long.",
  LoginForm__ERROR_COMPANY_DEACTIVATED:
    "This company ID has been deactivated. Please contact your SWABIZ representative for more information.",
  LoginForm__ERROR_DEFAULT:
    "We are unable to process your request. Please try again. If you continue to have difficulties, please contact a Southwest Airlines® Customer Representative at 1-800-I-FLY-SWA (1-800-435-9792).",
  LoginForm__ERROR_MAXIMUM_TRIES: "Maximum number of tries reached. Please try again later.",
  LoginForm__ERROR_PASSWORD_NOT_SET:
    "A password has not been set up for the account number entered. Please select 'Need help logging in?' to create an online account.",
  LoginForm__ERROR_SESSION_TIMEOUT: "Session timeout error. Please try again.",
  LoginForm__ERROR_TRAVEL_MANAGER_ACCOUNT_LOCKED:
    "You have exceeded the number of attempts allowed, your account will be locked for ten minutes.",
  LoginForm__ERROR_TRAVEL_MANAGER_INVALID_CREDENTIALS:
    "The Company ID, Travel Manager first name, last name, and/or password are incorrect.",
  LoginForm__ERROR_UNAVAILABLE:
    "We are currently unable to complete your request. Please try again later.",
  LoginForm__ERROR_USERNAME_PASSWORD_INCORRECT:
    "The username/account number and/or password are incorrect.",
  LoginForm__TITLE: "Log in",
  LoginForm__TITLE_LOG_IN_TO_CONTINUE: "Log in to continue",
};
