export default {
  DesktopHeaderControl__CREATE_ACCOUNT: "Create account",
  DesktopHeaderControl__GREETING: (option) => `Hi, ${option.name}`,
  DesktopHeaderControl__LOG_IN: "Log in",
  DesktopHeaderControl__LOG_OUT: "Log out",
  DesktopHeaderControl__MY_ACCOUNT: "My Account",
  DesktopHeaderControl__POINTS: " points",
  DesktopHeaderControl__TIER_A_LIST: "A-List",
  DesktopHeaderControl__TIER_A_LIST_PREFERRED: "A-List Preferred",
  DesktopHeaderControl__TIER_PENDING: "Status Pending",
  DesktopHeaderControl__TRAVELER_ACCOUNT: "Traveler Account",
};
