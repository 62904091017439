export default {
  ItineraryRecentSearch__ARIA_LABEL: "Recent searches",
  ItineraryRecentSearch__CLEAR: "Clear",
  ItineraryRecentSearch__DATE_FORMAT: "ddd, MMM. D",
  ItineraryRecentSearch__DISABLED: "Disabled",
  ItineraryRecentSearch__EMPTY: "You have no recent searches",
  ItineraryRecentSearch__ENABLE: "Enable recent searches",
  ItineraryRecentSearch__HEADING: "Recent searches",
  ItineraryRecentSearch__STOP_REMEMBERING: "Stop remembering",
  ItineraryRecentSearch__SUCCESS_MESSAGE:
    "You can always update the setting in the recent search menu in the booking widget.",
  ItineraryRecentSearch__TITLE_SUCCESS_MESSAGE: "Recent search data will not be remembered.",
};
