import superscript from "./transforms/superscript";

export * from "./Message";

/* eslint-disable no-console, lodash/prefer-lodash-typecheck */
let logError = (msg, component, data) => {
  console.warn(msg, component, data);
};

let lang = "en";
let i18nRoot;
let i18nReplacementKeys;

function i18n(key, data = {}) {
  let value;

  if (Array.isArray(key)) {
    value = key.map((it) => processSingleKey(it, data));
  } else {
    value = processSingleKey(key, data);
  }

  return value;
}

export default i18n;

export function setLang(value) {
  lang = value;
}

export function setI18nRoot(value) {
  i18nRoot = value;
}

export function updateI18n(value) {
  if (i18nRoot) {
    const entries = i18nRoot[lang];

    i18nRoot[lang] = Object.freeze(Object.assign({}, entries, value));
  } else {
    logError("failed update: i18n not previously configured", "i18n", {});
  }
}

export function setI18nReplacementKeys(value) {
  i18nReplacementKeys = value;
}

export function setErrorLogger(logger) {
  logError = logger;
}

function processSingleKey(key, data) {
  let value = key;
  const message = findMessage(key);

  if (message !== undefined) {
    if (typeof message === "string") {
      value = message;
    } else {
      try {
        value = message(data);
      } catch (error) {
        logError(error.message, "i18n", { key, data });
      }
    }
  }

  return superscript(value);
}

function findMessage(key) {
  const data = i18nRoot || {};
  const entries = data[lang] || {};
  const replacementEntries = i18nReplacementKeys || {};
  const finalKey = replacementEntries[key] || key;

  return entries[finalKey];
}
