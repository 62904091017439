export default {
  TravelerSelector__ADD_ROOM: "+ Add room",
  TravelerSelector__SELECT_AGE: "Select age",
  TravelerSelector__APPLY: "Apply",
  TravelerSelector__ARIA_LABEL__ADD_ROOM: (roomNumber) => `Add room ${roomNumber}`,
  TravelerSelector__ARIA_LABEL__CHILD_AGE_SELECTION: (childIndex) =>
    `Select age for child ${childIndex}`,
  TravelerSelector__ARIA_LABEL__LEARN_MORE:
    "Add your infant at the beginning to avoid any hiccups when booking your Getaways by Southwest® reservation.",
  TravelerSelector__ARIA_LABEL__REMOVE_ROOM: (roomNumber) => `Remove room ${roomNumber}`,
  TravelerSelector__ARIA_LABEL__TRAVELER_SELECTOR: "Travelers",
  TravelerSelector__BABY_ON_BOARD_TITLE: "Baby on board?",
  TravelerSelector__BABY_ON_BOARD_DESCRIPTION:
    "Add your infant at the beginning to avoid any hiccups when booking your Getaways by Southwest® reservation.",
  TravelerSelector__BABY_ON_BOARD_LEARN_MORE: "Learn More",
  TravelerSelector__CHILD: "Child",
  TravelerSelector__CHILD_NUMBER: (childNumber) => `Child ${childNumber}`,
  TravelerSelector__REMOVE_ROOM: "- Remove",
  TravelerSelector__ROOMS_COUNT: (rooms) => `${rooms} ${rooms > 1 ? "Rooms" : "Room"}`,
  TravelerSelector__ROOM_NUMBER: (roomNumber) => `Room ${roomNumber}`,
  TravelerSelector__TRAVELERS_COUNT: (travelers) =>
    `${travelers} ${travelers > 1 ? "Travelers" : "Traveler"}`,
};
