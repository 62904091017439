import ManageMyVacationsFormI18nKeys from "./ManageMyVacationsForm/ManageMyVacationsForm.i18";
import ManageMyVacationsWidgetI18nKeys from "./ManageMyVacationsWidget/ManageMyVacationsWidget.i18n";
import schemaI18nKeys from "./schema/schema.i18n";
import useVacationsFormLogicI18nKeys from "./useVacationsFormLogic/useVacationsFormLogic.i18n";
import PackageOptionsI18nKeys from "./VacationsBookingForm/PackageOptions/PackageOptions.i18n";
import PromoCodeI18nKeys from "./VacationsBookingForm/PromoCode/PromoCode.i18n";
import TravelerSelectorI18nKeys from "./VacationsBookingForm/TravelerSelector/TravelerSelector.i18n";
import TravelerSelectorInputFieldI18nKeys from "./VacationsBookingForm/TravelerSelector/TravelerSelectorInputField.i18n";
import VacationsBookingFormSlimI18nKeys from "./VacationsBookingForm/VacationsBookingFormSlim.i18n";
import PackageCardI18nKeys from "./VacationsPackagesPlacement/PackageCard/PackageCard.i18n";
import VacationsPackagesPlacementI18nKeys from "./VacationsPackagesPlacement/VacationsPackagesPlacement.i18n";
import VacationsRecentSearchI18nKeys from "./VacationsRecentSearch/VacationsRecentSearch.i18n";

export default {
  ...ManageMyVacationsFormI18nKeys,
  ...ManageMyVacationsWidgetI18nKeys,
  ...PackageCardI18nKeys,
  ...PackageOptionsI18nKeys,
  ...PromoCodeI18nKeys,
  ...schemaI18nKeys,
  ...TravelerSelectorI18nKeys,
  ...TravelerSelectorInputFieldI18nKeys,
  ...useVacationsFormLogicI18nKeys,
  ...VacationsBookingFormSlimI18nKeys,
  ...VacationsPackagesPlacementI18nKeys,
  ...VacationsRecentSearchI18nKeys,
};
