export default {
  VacationsRecentSearch__ADULT: "adult",
  VacationsRecentSearch__ADULTS: "adults",
  VacationsRecentSearch__ARIA_LABEL__CURRENT_PAGE: "Current page is ",
  VacationsRecentSearch__ARIA_LABEL__NEXT: "Next page",
  VacationsRecentSearch__ARIA_LABEL__PAGE_COUNT: "Total number of pages",
  VacationsRecentSearch__ARIA_LABEL__PREVIOUS: "Previous page",
  VacationsRecentSearch__CHILD: "child",
  VacationsRecentSearch__CHILDREN: "children",
  VacationsRecentSearch__ROOM: "room",
  VacationsRecentSearch__ROOMS: "rooms",
};
