export default {
  TravelerSelectorInputField__ADULTS_DESCRIPTION: "Age 18+",
  TravelerSelectorInputField__ADULTS_LABEL: "Adults",
  TravelerSelectorInputField__ARIA_LABEL__ADULTS_DECREMENT:
    "Decrease the number of adults passengers. The minimum number is one.",
  TravelerSelectorInputField__ARIA_LABEL__ADULTS_INCREMENT:
    "Increase the number of adult passengers.",
  TravelerSelectorInputField__ARIA_LABEL__ADULTS_VALUE: ({ roomNumber }) =>
    `The amount of adult passengers in room ${roomNumber} is`,
  TravelerSelectorInputField__ARIA_LABEL__CHILDREN_DECREMENT:
    "Decrease the number of children passengers.",
  TravelerSelectorInputField__ARIA_LABEL__CHILDREN_INCREMENT:
    "Increase the number of children passengers.",
  TravelerSelectorInputField__ARIA_LABEL__CHILDREN_VALUE: ({ roomNumber }) =>
    `The amount of children passengers in room ${roomNumber} is`,
  TravelerSelectorInputField__ARIA_LABEL__LAPCHILDREN_DECREMENT:
    "Decrease the number of lap children passengers.",
  TravelerSelectorInputField__ARIA_LABEL__LAPCHILDREN_INCREMENT:
    "Increase the number of lap children passengers.",
  TravelerSelectorInputField__ARIA_LABEL__LAPCHILDREN_VALUE: ({ roomNumber }) =>
    `The amount of lap children passengers in room ${roomNumber} is`,
  TravelerSelectorInputField__CHILDREN_ADDITIONAL_MESSAGE: "(Excluding lap children)",
  TravelerSelectorInputField__CHILDREN_DESCRIPTION: "Age 0-17",
  TravelerSelectorInputField__CHILDREN_LABEL: "Children",
  TravelerSelectorInputField__LAPCHILDREN_DESCRIPTION: "Age under 2",
  TravelerSelectorInputField__LAPCHILDREN_LABEL: "Lap Children",
};
