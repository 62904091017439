import DesktopHeaderControlI18nKeys from "./DesktopHeaderControl/DesktopHeaderControl.i18n";
import DotcomMobileFooterI18nKeys from "./DotcomFooter/DotcomMobileFooter/DotcomMobileFooter.i18n";
import DotcomHeaderDesktopI18nKeys from "./DotcomHeader/DotcomHeaderDesktop/DotcomHeaderDesktop.i18n";
import CopyrightI18nKeys from "./DotcomHeader/DotcomHeaderMobile/Copyright.i18n";
import DotcomHeaderMobileI18nKeys from "./DotcomHeader/DotcomHeaderMobile/DotcomHeaderMobile.i18n";
import LocaleSwitcherI18nKeys from "./LocaleSwitcher/LocaleSwitcher.i18n";
import MobileHeaderControlI18nKeys from "./MobileHeaderControl/MobileHeaderControl.i18n";
import SearchAutocompleteI18nKeys from "./SearchAutocomplete/SearchAutocomplete.i18n";
import StartupMessageI18nKeys from "./StartupMessage/StartupMessage.i18n";

export default {
  ...CopyrightI18nKeys,
  ...DesktopHeaderControlI18nKeys,
  ...DotcomHeaderDesktopI18nKeys,
  ...DotcomHeaderMobileI18nKeys,
  ...DotcomMobileFooterI18nKeys,
  ...LocaleSwitcherI18nKeys,
  ...MobileHeaderControlI18nKeys,
  ...SearchAutocompleteI18nKeys,
  ...StartupMessageI18nKeys,
};
