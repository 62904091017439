export default {
  MobileHeaderControl__GREETING: (option) => `Hi, ${option.name}`,
  MobileHeaderControl__LOGIN_PURPOSE: "to manage your trips and account activity.",
  MobileHeaderControl__LOG_IN_ENROLL: "Log in / Enroll",
  MobileHeaderControl__LOG_IN_OR_ENROLL: "Log in or enroll",
  MobileHeaderControl__LOG_OUT: "Log out",
  MobileHeaderControl__MY_ACCOUNT_LINK_TEXT: "View Account",
  MobileHeaderControl__POINTS_ABBREVIATED: "PTS",
  MobileHeaderControl__TIER_A_LIST: "A-List",
  MobileHeaderControl__TIER_A_LIST_PREFERRED: "A-List Preferred",
  MobileHeaderControl__TIER_PENDING: "Status Pending",
};
