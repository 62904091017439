export default {
  BaseAuthenticationForm__ACCOUNT_NUMBER_OR_USERNAME: "Account number or username",
  BaseAuthenticationForm__BUTTON__LOG_IN: "Log in",
  BaseAuthenticationForm__COMPANY_ID: "Company ID Number",
  BaseAuthenticationForm__FORGOT_PASSWORD: "Forgot password?",
  BaseAuthenticationForm__FORGOT_PASSWORD_ARIA_LABEL: "Opens pages to reset lost password.",
  BaseAuthenticationForm__FORGOT_USERNAME: "Forgot login?",
  BaseAuthenticationForm__FORGOT_USERNAME_ARIA_LABEL: "Opens page to retrieve lost username.",
  BaseAuthenticationForm__LINK__ENROLL_NOW: "Enroll Now",
  BaseAuthenticationForm__PASSWORD: "Password",
  BaseAuthenticationForm__REAUTH_MESSAGE: "In the middle of something?",
  BaseAuthenticationForm__REMEMBER_ME: "Remember me",
  BaseAuthenticationForm__TEXT__NOT_A_MEMBER: "Not a member?",
};
